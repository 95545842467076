import { Domain } from 'api';

export const PRICE_FORMAT = /^[0-9.,]+$/;

export function priceFromString(value: number | string | null | undefined, inCents = true): number | undefined {
    if (typeof value === 'number') {
        return value;
    }

    if (value === null || value === undefined) {
        return undefined;
    }

    let cleanVal = value;
    if (value.indexOf('.') > -1 && value.indexOf(',') > -1) {
        cleanVal = cleanVal.replace(',', '');
    } else if (value.indexOf(',') > -1) {
        cleanVal = cleanVal.replace(',', '.');
    }

    return parseFloat(cleanVal) * (inCents ? 100 : 1);
}

export function formatPrice(
    price: number,
    locale?: string,
    currency?: string,
    noCents = false,
    hideCurrencySymbol = false,
    options?: Intl.NumberFormatOptions,
): string {
    const formatter = new Intl.NumberFormat(locale ? locale.replace('_', '-') : undefined, {
        ...options,
        style: 'currency',
        currency,
        minimumFractionDigits: noCents ? 0 : 2,
        maximumFractionDigits: noCents ? 0 : 2,
        currencyDisplay: hideCurrencySymbol ? 'code' : 'symbol',
    });

    let result = formatter.format(price);

    if (hideCurrencySymbol) {
        result = result.replace(/[a-z]{3}/i, '').trim();
    }

    return result;
}

export function isPriceWithLegacyCurrency(price: Domain.Price | Domain.PriceWithLegacyCurrency): price is Domain.PriceWithLegacyCurrency {
    return typeof price.currency === 'string';
}
