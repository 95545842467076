export interface StartDeviceTransactionPayload {
    externalReferenceId: string;
    amountInEuroCents: number;
    transactionId: string;
    deviceId: string;
}

export interface CancelDeviceTransactionPayload {
    transactionId: string;
    deviceId: string;
}

export type DeviceTransactionPaymentStatus =
    | 'CANCEL'
    | 'EXPIRED'
    | 'REFUNDING'
    | 'REFUND'
    | 'PENDING'
    | 'VERIFY'
    | 'AUTHORIZE'
    | 'PARTLY_CAPTURED'
    | 'PAID'
    | 'PAID_CHECKAMOUNT'
    | 'FAILURE'
    | 'DENIED'
    | 'CHARGEBACK'
    | 'PARTIAL_REFUND'
    | 'PARTIAL_PAYMENT'
    | 'REQUEST';

export interface DeviceTransactionStatus {
    transactionId: string;
    transactionPaymentStatus?: DeviceTransactionPaymentStatus;
}

export function deviceTransactionPaymentStatusIsIntermediary(status: DeviceTransactionPaymentStatus) {
    if (status === 'PENDING' || status === 'VERIFY' || status === 'AUTHORIZE' || status === 'PARTIAL_PAYMENT') {
        return true;
    }

    return false;
}
